import { extendTheme } from '@chakra-ui/react';

const theme = extendTheme({
  colors: {
    blue: {
      50: '#E6F0FF',
      100: '#BFDAFF',
      200: '#99C3FF',
      300: '#73ADFF',
      400: '#4D96FF',
      500: '#21354D',
      600: '#1A2B3D',
      700: '#132130',
      800: '#0D1623',
      900: '#080E17',
    },
    gray: {
      50: '#F3F3F3',
      100: '#EDF2F7',
      200: '#E2E8F0',
      300: '#CBD5E0',
      400: '#A0AEC0',
      500: '#718096',
      600: '#4A5568',
      700: '#2D3748',
      800: '#1A202C',
      900: '#171923',
    },
    yellow: {
      500: '#C7871D',
      600: '#A76D18',
      700: '#875413',
    },
    brown: {
      500: '#6B3F26',
      600: '#54301E',
      700: '#3D2216',
    },
    white: '#FFFFFF',
    black: {
      500: '#212121',
      'gradient': 'linear-gradient(45deg, hsl(213 40% 14% / 1), hsl(213 40% 9% / 1))'
    }
  },
  fonts: {
    heading: "'Unna', serif",
    body: "'Belleza', sans-serif",
  },
  components: {
    Button: {
      baseStyle: {
        fontWeight: 'bold',
        borderRadius: 'full',
        transition: 'all 0.3s',
      },
      sizes: {
        lg: {
          fontSize: 'lg',
          px: 10,
          py: 7,
        },
      },
      variants: {
        solid: {
          bg: 'yellow.500',
          color: 'white',
          
          _hover: {
            bg: 'yellow.600',
            transform: 'translateY(-2px)',
            boxShadow: 'lg',
          },
          _active: {
            bg: 'yellow.700',
            transform: 'translateY(0)',
            boxShadow: 'none',
          },
          _disabled:{
            bg: 'yellow.500',
            opacity: 0.5,
            pointerEvents: 'none'
          }
        },
        outline: {
          borderWidth: '2px',
          borderColor: 'yellow.500',
          color: 'yellow.500',
          _hover: {
            bg: 'yellow.10',
            transform: 'translateY(-2px)',
            boxShadow: 'lg',
          },
          _active: {
            bg: 'yellow.10',
            transform: 'translateY(0)',
            boxShadow: 'none',
          },
        },
      },
    },
    Heading: {
      baseStyle: {
        fontWeight: 'bold',
        color: 'blue.500',
      },
      sizes: {
        '2xl': {
          fontSize: ['4xl', null, '5xl'],
          lineHeight: 1.2,
        },
      },
    },
    Text: {
      baseStyle: {
        color: 'blue.700',
      },
      variants: {
        body: {
          fontSize: 'lg',
          lineHeight: 1.6,
          color: 'brown.600',
        },
        title: {
          fontSize: '2xl',
          fontWeight: 'bold',
          lineHeight: 1.3,
          color: 'blue.800',
        },
      },
    },
    Input: {
      variants: {
        filled: {
          field: {
            borderRadius: 'full',
            bg: 'gray.100',
            _focus: {
              bg: 'white',
              borderColor: 'blue.500',
              boxShadow: 'outline',
            },
          },
        },
      },
      sizes: {
        lg: {
          field: {
            fontSize: 'lg',
            px: 6,
            py: 4,
          },
        },
      },
    },
    Textarea: {
      variants: {
        filled: {
          borderRadius: 'xl',
          bg: 'gray.100',
          _focus: {
            bg: 'white',
            borderColor: 'blue.500',
            boxShadow: 'outline',
          },
        },
      },
      sizes: {
        lg: {
          fontSize: 'lg',
          px: 6,
          py: 4,
        },
      },
    },
  },
  styles: {
    global: {
      'html, body': {
        color: 'gray.700',
        lineHeight: 'tall',
        bg: 'gray.50',
      },
      a: {
        color: 'blue.500',
        _hover: {
          textDecoration: 'none',
          color: 'blue.600',
        },
      },
    },
  },
  config: {
    initialColorMode: 'light',
    useSystemColorMode: false,
  },
});

export default theme;