import React, { createContext, useState, useEffect, useCallback, useMemo } from 'react';
import axios from 'axios';

const AuthContext = createContext();

const AuthProvider = ({ children }) => {
  const [user, setUser] = useState(null);
  const [loading, setLoading] = useState(true);
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const [isAdminMode, setIsAdminMode] = useState(false);

  const api = useMemo(() => axios.create({
    baseURL: 'https://a2sqg3kyxa7tzjvhmvcgewy52y0hppyw.lambda-url.sa-east-1.on.aws',
    timeout: 5000,
  }), []);

  const fetchUserData = useCallback(async () => {
    const token = localStorage.getItem('token');
    if (!token) {
      setLoading(false);
      setIsAuthenticated(false);
      return null;
    }
  
    try {
      api.defaults.headers.common['Authorization'] = `Bearer ${token}`;
      const response = await api.get('/logado/me');
      const userData = response.data;
      setUser(userData);
      setIsAuthenticated(true);
      setIsAdminMode(userData.role === "Admin");
      setLoading(false);
      return userData;
    } catch (error) {
      console.error('Error fetching user data:', error);
      setUser(null);
      setIsAuthenticated(false);
      setIsAdminMode(false);
      setLoading(false);
      return null;
    }
  }, [api]);

  useEffect(() => {
    fetchUserData();
  }, [fetchUserData]);

  const login = useCallback(async (email, password, additionalData = null) => {
    try {
      const endpoint = additionalData ? '/cadastro/cadastro' : '/entrar/login';
      const data = additionalData
        ? { ...additionalData, email, senha: password }
        : { email, senha: password };
  
      const response = await api.post(endpoint, data);
      const { token } = response.data;
      localStorage.setItem('token', token);
      api.defaults.headers.common['Authorization'] = `Bearer ${token}`;
      const userData = await fetchUserData();
      return userData;
    } catch (error) {
      throw new Error(error.response?.data?.message || 'Erro ao processar a solicitação');
    }
  }, [api, fetchUserData]);

  const logout = useCallback(() => {
    localStorage.removeItem('token');
    delete api.defaults.headers.common['Authorization'];
    setUser(null);
    setIsAuthenticated(false);
    setIsAdminMode(false);
  }, [api]);

  const updateUser = useCallback((updatedUser) => {
    setUser(updatedUser);
  }, []);

  const enableAdminMode = useCallback(() => {
    setIsAdminMode(true);
  }, []);

  const disableAdminMode = useCallback(() => {
    setIsAdminMode(false);
  }, []);

  const value = useMemo(() => ({
    user,
    login,
    logout,
    updateUser,
    loading,
    isAuthenticated,
    isAdminMode,
    enableAdminMode,
    disableAdminMode,
    fetchUserData,
  }), [user, login, logout, updateUser, loading, isAuthenticated, isAdminMode, enableAdminMode, disableAdminMode, fetchUserData]);

  return (
    <AuthContext.Provider value={value}>
      {children}
    </AuthContext.Provider>
  );
};

export { AuthContext, AuthProvider };
