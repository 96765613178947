import React, { useContext } from 'react';
import { Navigate, Outlet } from 'react-router-dom';
import { AuthContext } from '../context/AuthContext';
import Loader from '../components/Loader';

const ProtectedRoute = ({ adminOnly }) => {
  const { user, loading, isAuthenticated } = useContext(AuthContext);

  if (loading) {
    return <Loader />; // Ou qualquer componente de carregamento que você preferir
  }

  if (!isAuthenticated) {
    return <Navigate to="/entrar" />;
  }

  if (adminOnly && (user.role && user.role !== "Admin")) {
    return <Navigate to="/" />;
  }

  return <Outlet />;
};

export default ProtectedRoute;