import React from 'react';
import { ChakraProvider, useBreakpointValue } from '@chakra-ui/react';
import AppRoutes from "./routes";
import theme from './theme';

const App = () => {
  return (
    <ChakraProvider theme={theme}>
        <AppRoutes />
    </ChakraProvider>
  );
};

export default App;
