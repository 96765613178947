import React, { Suspense, lazy, useContext } from 'react';
import { BrowserRouter, Routes, Route, Navigate } from 'react-router-dom';
import Loader from '../components/Loader';
import ProtectedRoute from './ProtectedRoute';
import { AuthContext } from '../context/AuthContext';

const Home = lazy(() => import('../pages/Home')); //ok
const Cursos = lazy(() => import('../pages/Cursos')); // ok
const Curso = lazy(() => import('../pages/Curso'));  //ok
const Login = lazy(() => import('../pages/Login'));  // ok
const Suporte = lazy(() => import('../pages/Suporte'));  // ok
const Cadastro = lazy(() => import('../pages/Cadastro')); // ok
const ResetPassword = lazy(() => import('../pages/ResetPassword')); // ok
const PoliticasDePrivacidade = lazy(() => import('../pages/PoliticasDePrivacidade')); // ok
const TermosDeUso = lazy(() => import('../pages/TermosDeUso')); // ok
const Aula = lazy(() => import('../pages/Aula'));
const Profile = lazy(() => import('../pages/Perfil')); //ok
const AdminCursos = lazy(() => import('../pages/AdminCursos'));  // ok
const AdminModulos = lazy(() => import('../pages/AdminModulos')); // ok
const AdminAulas = lazy(() => import('../pages/AdminAulas')); // ok
const AdminAlunos = lazy(() => import('../pages/AdminAlunos')); //ok

const AppRoutes = () => {
  const { isAuthenticated, isAdminMode } = useContext(AuthContext);

  let routeConfig;

  if (isAdminMode) {
    routeConfig = [
      { path: "/", element: <AdminCursos />, protected: true, adminOnly: true },
      { path: "/cursos", element: <AdminCursos />, protected: true, adminOnly: true },
      { path: "/modulos", element: <AdminModulos />, protected: true, adminOnly: true },
      { path: "/aulas", element: <AdminAulas />, protected: true, adminOnly: true },
      { path: "/alunos", element: <AdminAlunos />, protected: true, adminOnly: true },
    ];
  } else {
    routeConfig = [
      { path: "/", element: <Home />, protected: false },
      { path: "/cursos", element: <Cursos />, protected: false },
      { path: "/curso/:id", element: <Curso />, protected: false },
      { path: "/suporte", element: <Suporte />, protected: false },
      { path: "/aula/:id", element: <Aula />, protected: true },
      { path: "/politicas-de-privacidade", element: <PoliticasDePrivacidade />, protected: false },
      { path: "/termos-de-uso", element: <TermosDeUso />, protected: false },
      { path: "/perfil", element: <Profile />, protected: true },
    ];

    if (!isAuthenticated) {
      routeConfig.push(
        { path: "/entrar", element: <Login />, protected: false },
        { path: "/cadastro", element: <Cadastro />, protected: false },
        { path: "/redefinir-senha", element: <ResetPassword />, protected: false },
        { path: "/redefinir-senha/:token", element: <ResetPassword />, protected: false },
      );
    }
  }

  return (
    <BrowserRouter>
      <Suspense fallback={<Loader />}>
        <Routes>
          {routeConfig.map(({ path, element, protected: isProtected, adminOnly }, index) => (
            isProtected ? (
              <Route key={index} element={<ProtectedRoute adminOnly={adminOnly} />}>
                <Route path={path} element={element} />
              </Route>
            ) : (
              <Route key={index} path={path} element={element} />
            )
          ))}
          {/* Redireciona qualquer rota não definida para a página inicial */}
          <Route path="*" element={<Navigate to={isAuthenticated ? '/' : '/entrar'} />} />
        </Routes>
      </Suspense>
    </BrowserRouter>
  );
};

export default AppRoutes;